import React, { useCallback, useEffect, useState } from "react";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { Card } from "../../components/card";
import { CircleButton } from "../../components/button";
import { DataTable } from "../../components/table";
import { Services } from "../../http";
import { networkErrorHandeller } from "../../helpers";

export const OrderIndex = () => {
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setLoading] = useState(true);

  /* fetch data */
  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await Services.Order.index({ page, limit: perPage });
        if (response.status === 200) {
          setData(response?.data?.data?.data);
          setTotalRows(response?.data?.data?.total);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          networkErrorHandeller(error);
        }
      }
    },
    [perPage]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  // handle paginate page change
  const handlePageChange = (page) => fetchData(page);

  // handle paginate row change
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await Services.Order.index({ page, limit: newPerPage });
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  // data columns
  const columns = [
    {
      name: "SL",
      sortable: true,
      maxWidth: "100px",
      selector: (row, index) => index + 1,
    },
    {
      name: "View",
      grow: 0,
      cell: (row) => (
        <Link to={`/dashboard/order/${row.id}`}>
          <CircleButton type="button">
            <Eye size={18} />
          </CircleButton>
        </Link>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.order_status,
    },
    {
      name: "Grand Total",
      sortable: true,
      selector: (row) => row.grand_total + " tk",
    },
    {
      name: "Delivery address",
      sortable: true,
      selector: (row) => row.delivery_address,
    },
  ];

  return (
    <div>
      <Card className="mb-3">
        <p className="text-[16px] text-gray-800 font-medium">Recent orders</p>
      </Card>

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          totalRows={totalRows}
          pagination
          paginationServer
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />
      </Card>
    </div>
  );
};

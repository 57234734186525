import { useForm } from "react-hook-form";
import { PrimaryButtonV2 } from "../button";
import { isValidPhone } from "../../helpers";
import { TextField } from "../input-field";

export const ResetForm = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Phone */}
      <div className="mb-6">
        <TextField
          label="Phone number"
          name="phone"
          control={control}
          defaultvalue={""}
          error={errors.phone && errors.phone.message}
          placeholder={"01XX-XXXX-XXX"}
          rules={{
            required: "Phone number is required",
            pattern: {
              value: isValidPhone(),
              message: "Invalid phone number.",
            },
          }}
        />
      </div>

      {/* Submit button */}
      <div className="flex flex-col">
        <div className="mb-6">
          <PrimaryButtonV2 type="submit" disabled={props.isLoading}>
            {props.isLoading ? "Loading..." : "Reset"}
          </PrimaryButtonV2>
        </div>
      </div>
    </form>
  );
};

import Logo from "../static/logo.png";
import UserAvatar from "../static/user.jpg";
import FourOFour from "../static/204.png";
import NetError from "../static/501.png";
import Dummy from "../static/dummy.jpg";

export const Images = {
  Logo,
  UserAvatar,
  FourOFour,
  NetError,
  Dummy,
};

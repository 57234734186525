import React, { useCallback, useEffect, useState } from "react";
import { Services } from "../../http";
export const ComponentToPrint = React.forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [show, setShow] = useState(false);
  const [updating, setUpdating] = useState(false);

;

  const date = new Date(data?.order.created_at);
  // console.log(date.toLocaleDateString()) // 3/28/2022 (depending on locale)
    const order_date  = date.toLocaleDateString();


  /* fetch data */
  const fetchData = useCallback(
    async (id) => {
      try {
        setLoading(true);
        setServerError(false);
        setData(null);
        const response = await Services.Order.show(props.id);
        console.log(response);
        if (response && response.status === 200) {
          setData(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [props.id]
  );


  useEffect(() => {
    if (props.id) fetchData(props.id);
  }, [props.id, fetchData]);


  return (
    <div ref={ref} className="px-3 my-10">
      <section className="w-[290px]">
        {/* header part */}
        <div className="text-center">
          <h1 className="text-3xl font-bold">GrapMart</h1>
          <p>Dattapara, Ashulia, Saver, Dhaka</p>
          <p>+880 1408-685333</p>
        </div>
        {/* end header part */}


        {/* customer information start */}
        <div className="flex justify-between items-center mt-5 mb-2">
          <span>Order No: #00{data?.order?.id}</span>
          <span>Date: {order_date}</span>
        </div>
        <hr class="border-dotted border-[1px] border-gray-900" />

        <div className="my-3">
          <div className="grid grid-cols-4">
            <div className=" col-span-1">
              <p>Name :</p>
              <p>Phone :</p>
              <p>Address :</p>
            </div>
            <div className=" col-span-3">
              <p>{data?.order?.name}</p>
              <p>{data?.order?.phone}</p>
              <p>{data?.order?.delivery_address}</p>
            </div>
          </div>
        </div>
        <hr class="border-dotted border-[1px] border-gray-900" />
        {/* customer information end */}

        {/* product information start */}
        {data?.products.map((item, i) => {
          return (
            <div key={i} className="my-2">
              <span>{item?.name}</span>
              <p className="flex items-center justify-between text-[12px]">
                <span>
                  {item?.quantity} x {item?.price}
                </span>
                <span>{item?.quantity * item?.price} Tk</span>
              </p>
            </div>
          );
        })}

        <hr class="border-dotted border-[1px] border-gray-900" />
        {/* product information start */}

        {/* amount start here */}
        <div className="my-3">
          {/* sub total */}
          <p className="flex items-center justify-between">
            <span className="font-bold">Sub Total</span>
            <span>{parseFloat(data?.order?.sub_total).toFixed(2)}</span>
          </p>

          {/* deleviery cost  */}
          <p className="flex items-center justify-between">
            <span className="">Delivery cost</span>
            <span>{parseFloat(data?.order?.delivery_cost).toFixed(2)}</span>
          </p>

          {/* Grand Total */}
          <p className="flex items-center justify-between">
            <span className="font-bold">Grand Total</span>
            <span>{parseFloat(data?.order?.grand_total).toFixed(2)}</span>
          </p>
        </div>
        <hr class="border-dotted border-[1px] border-gray-900" />
        {/* amount end here */}

        {/* footer area start here */}
        <div className="my-4 text-center">
          <p>
            Hi, <span className="font-bold">{data?.order?.name}</span>. Thank
            you <br />
            for your recent purchase!!!
          </p>
        </div>
        {/* footer area start here */}
      </section>
    </div>
  );
});

import React from "react";
import { SidebarItems } from "../sidebar-items";
import { Images } from "../../utils/images";

export const Sidebar = () => {
  return (
    <div className="w-[260px] fixed top-0 left-0 h-full z-30 bg-white hidden lg:block">
      {/* Header */}
      <div className="p-3">
        <img
          src={Images.Logo}
          alt="Logo"
          className="w-[45px] h-[45px] mx-auto"
        />
      </div>

      {/* Body */}
      <div className="px-4 py-2">
        <SidebarItems />
      </div>
    </div>
  );
};

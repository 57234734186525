import { publicAuthRequest } from "../config/axios.config";

/* Login */
const login = async (data) => {
  return await publicAuthRequest.post(`/login`, data);
};

/* Password reset */
const reset = async (data) => {
  return await publicAuthRequest.post(`/reset-password`, data);
};

/* Resend verification code */
const resendVerificationCode = async (data) => {
  return await publicAuthRequest.post(
    `/resend-password-verification-code`,
    data
  );
};

/* Verify verification code */
const verifyVerificationCode = async (data) => {
  return await publicAuthRequest.post(
    `/verify-password-verification-code`,
    data
  );
};

/* Set new password */
const setPassword = async (data) => {
  return await publicAuthRequest.post(`/set-password`, data);
};

export const Auth = {
  login,
  reset,
  resendVerificationCode,
  verifyVerificationCode,
  setPassword,
};

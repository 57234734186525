export const order_status_data = [
  {
    label: "Created",
    value: "Created",
    description: "Order created.",
  },
  {
    label: "Accepted",
    value: "Accepted",
    description: "Order is accepted.",
  },
  {
    label: "Pending",
    value: "Pending",
    description: "Order in pending.",
  },
  {
    label: "Processing",
    value: "Processing",
    description: "Order in Processing.",
  },
  {
    label: "Shipped",
    value: "Shipped",
    description: "Order in Shipped.",
  },
  {
    label: "Delivered",
    value: "Delivered",
    description: "Order has delivered.",
  },
  {
    label: "Canceled",
    value: "Canceled",
    description: "Order is canceled.",
  },
];

import axios from "axios";
const apiUrl = process.env.REACT_APP_API_ENDPOINT || "";
const authApiUrl = process.env.REACT_APP_AUTH_API_ENDPOINT || "";
const documentApiUrl = process.env.REACT_APP_DOCUMENT_API_ENDPOINT || "";

// common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const publicRequest = axios.create({
  baseURL: apiUrl,
});

const publicAuthRequest = axios.create({
  baseURL: authApiUrl,
});

const privateRequest = axios.create({
  baseURL: apiUrl,
});

const documentPrivateRequest = axios.create({
  baseURL: documentApiUrl,
});

// Add a request interceptor
privateRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (token) {
      config.headers["Authorization"] = "Bearer " + token || "";
    }
    return config;
  },
  (err) => {
    console.log(err);
    Promise.reject(err);
  }
);

// Add a document request interceptor
documentPrivateRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (token) {
      config.headers["Authorization"] = "Bearer " + token || "";
    }
    return config;
  },
  (err) => {
    console.log(err);
    Promise.reject(err);
  }
);

export {
  publicRequest,
  publicAuthRequest,
  privateRequest,
  documentPrivateRequest,
};

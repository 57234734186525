import React from "react";
import { NoContent } from "../404";
import { TableLoader } from "../loader";
import ReactDataTable from "react-data-table-component";

export const DataTable = (props) => {
  const customStyles = {
    cells: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  };

  return (
    <ReactDataTable
      columns={props.columns}
      data={props.data}
      progressPending={props.loading}
      progressComponent={<TableLoader />}
      customStyles={customStyles}
      noDataComponent={
        <NoContent message={props.noDataMessage || "No content available."} />
      }
      pagination={props.pagination}
      paginationServer={props.paginationServer}
      paginationTotalRows={props.totalRows}
      onChangeRowsPerPage={props.handlePerRowsChange}
      onChangePage={props.handlePageChange}
    />
  );
};

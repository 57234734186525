import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Edit, Plus, Trash2 } from "react-feather";
import { Services } from "../../http";
import { Card } from "../../components/card";
import { DataTable } from "../../components/table";
import { networkErrorHandeller } from "../../helpers";
import {
  CircleButton,
  DangerButton,
  PrimaryButton,
} from "../../components/button";
import { Modal } from "../../components/modal";
import { Toastify } from "../../components/toastify";

export const AreaIndex = () => {
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [willDestroy, setWillDestroy] = useState({
    show: false,
    id: null,
    loading: false,
  });

  /* fetch data */
  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await Services.Area.index({ page, limit: perPage });
        if (response.status === 200) {
          setData(response?.data?.data?.data);
          setTotalRows(response?.data?.data?.total);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          networkErrorHandeller(error);
        }
      }
    },
    [perPage]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  // handle paginate page change
  const handlePageChange = (page) => fetchData(page);

  // handle paginate row change
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await Services.Area.index({ page, limit: newPerPage });
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  /* handle destroy */
  const handleDestroy = async () => {
    try {
      setWillDestroy({ ...willDestroy, loading: true });
      const response = await Services.Area.destroy(willDestroy.id);
      if (response && response.status === 200) {
        fetchData();
        Toastify.Success(response.data.message);
      }
      setWillDestroy({ id: null, loading: false, show: false });
    } catch (error) {
      if (error) {
        setWillDestroy({ id: null, loading: false, show: false });
        networkErrorHandeller(error);
      }
    }
  };

  // data columns
  const columns = [
    {
      name: "SL",
      sortable: true,
      width: "70px",
      selector: (_, index) => index + 1,
    },
    {
      name: "Address",
      grow: 1,
      selector: (row) => row.address,
    },
    {
      name: "Delivery Charge",
      selector: (row) => row.delivery_charge + " tk",
    },
    {
      name: "Action",
      width: "120px",
      cell: (row) => (
        <div className="flex gap-1">
          <DangerButton
            type="button"
            className="rounded-full !p-2"
            onClick={() =>
              setWillDestroy({ id: row.id, loading: false, show: true })
            }
          >
            <Trash2 size={18} />
          </DangerButton>
          <Link to={`/dashboard/area/${row.id}`}>
            <CircleButton type="button">
              <Edit size={18} />
            </CircleButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Card className="mb-3 flex justify-between">
        <div>
          <p className="text-[15px] pt-[6px] text-gray-800 font-medium">
            List of area & charges
          </p>
        </div>
        <div>
          <Link to="/dashboard/area/create">
            <CircleButton type="button">
              <Plus size={18} />
            </CircleButton>
          </Link>
        </div>
      </Card>

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          totalRows={totalRows}
          pagination
          paginationServer
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />
      </Card>

      {/* Delete confirmation modal */}
      <Modal
        show={willDestroy.show}
        onHide={() => setWillDestroy({ id: null, loading: false, show: false })}
        title="Are you sure?"
      >
        <p className="mb-4">Want to delete this area.</p>
        <div>
          <DangerButton
            type="button"
            className="mr-2"
            onClick={() => handleDestroy()}
            disabled={willDestroy.loading}
          >
            {willDestroy.loading ? "Deleting..." : "Yes"}
          </DangerButton>
          <PrimaryButton
            type="button"
            onClick={() =>
              setWillDestroy({ id: null, loading: false, show: false })
            }
            disabled={willDestroy.loading}
          >
            No
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

import { privateRequest } from "../config/axios.config";

/* List of resources */
const index = async (data) => {
  return privateRequest.get(`/order`, { params: { ...data } });
};

/* Shoq specific resources */
const show = async (id) => {
  return privateRequest.get(`/order/${id}`);
};

/* Update specific resource */
const update = async (id, data) => {
  return privateRequest.put(`/order/${id}`, data);
};


/* Shoq specific resources */
const orderCancel = async (id) => {
  return privateRequest.get(`/order-cancel/${id}`);
};

export const Order = {
  index,
  show,
  update,
  orderCancel
};

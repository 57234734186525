import React, { useState } from "react";
import { Services } from "../../http";
import { ChevronLeft } from "react-feather";
import { Card } from "../../components/card";
import { Link, useHistory } from "react-router-dom";
import { Toastify } from "../../components/toastify";
import { networkErrorHandeller } from "../../helpers";
import { CircleButton } from "../../components/button";
import { AdsForm } from "../../components/form/ads.form";

export const AddStore = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  /* handle form submit to server */
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await Services.Ads.store(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }

      setLoading(false);
      history.push("/dashboard/ads");
    } catch (error) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <Card className="mb-3 flex justify-between">
        <div>
          <p className="text-[15px] pt-[6px] text-gray-800 font-medium">
            New ads
          </p>
        </div>
        <div>
          <Link to="/dashboard/ads">
            <CircleButton type="button">
              <ChevronLeft size={18} />
            </CircleButton>
          </Link>
        </div>
      </Card>

      <Card className="p-4 md:p-6 w-full md:w-[600px] mx-auto">
        <AdsForm loading={isLoading} onSubmit={(data) => handleSubmit(data)} />
      </Card>
    </div>
  );
};

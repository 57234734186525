import { documentPrivateRequest } from "../config/axios.config";

/* List of resources */
const index = async (data) => {
  return documentPrivateRequest.get(`/admin`, { params: { ...data } });
};

/* Show specific resource */
const show = async (id) => {
  return documentPrivateRequest.get(`/admin/${id}`);
};

/* Change specific resource status */
const changeStatus = async (id, data) => {
  return documentPrivateRequest.put(`/admin/${id}`, data);
};

/* Add specific resource charge */
const addCharge = async (id, data) => {
  return documentPrivateRequest.put(`/admin/add-charge/${id}`, data);
};

export const Document = {
  index,
  show,
  changeStatus,
  addCharge,
};

import { privateRequest } from "../config/axios.config";

/* List of resources */
const index = async () => {
  return privateRequest.get(`/banner`);
};

/* Store new resources */
const store = async (data) => {
  return privateRequest.post(`/banner`, data);
};

/* Destroy specific resource */
const destroy = async (id) => {
  return privateRequest.delete(`/banner/${id}`);
};

export const Banner = {
  index,
  store,
  destroy,
};

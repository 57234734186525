import { privateRequest } from "../config/axios.config";

/* List of resources */
const index = async () => {
  return privateRequest.get(`/dashboard`);
};

export const Dashboard = {
  index,
};

import { privateRequest } from "../config/axios.config";

/* List of resources */
const index = async () => {
  return privateRequest.get(`/ads`);
};

/* Store new resources */
const store = async (data) => {
  return privateRequest.post(`/ads`, data);
};

/* Destroy specific resource */
const destroy = async (id) => {
  return privateRequest.delete(`/ads/${id}`);
};

export const Ads = {
  index,
  store,
  destroy,
};

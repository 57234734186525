import { useController } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Text } from "../text";

// Required Props for text field (input type)
// 1. label
// 2. error
// 3. name
// 4. defaultValue
// 5. placeholder
// 6. control
// 7. rules
// 8. icon 20X20

export const TextField = ({
  label,
  error,
  name,
  defaultvalue,
  placeholder,
  control,
  rules,
  icon,
  type,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultvalue,
  });

  return (
    <div>
      {error ? (
        <Text className="text-sm text-red-500 mb-1">{error}</Text>
      ) : (
        <Text className="text-sm text-gray-700 mb-1">{label}</Text>
      )}

      <div className={icon ? "relative" : ""}>
        <input
          onChange={onChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          value={value} // input value
          name={name} // send down the input name
          ref={ref} // send input ref, so we can focus on input when error appear
          placeholder={placeholder}
          type={type || "text"}
          min={1}
          className={
            error
              ? "w-full text-sm bg-gray-100 rounded-md outline-none p-4 border-red-400"
              : "w-full text-sm bg-gray-100 rounded-md outline-none p-4"
          }
        />
      </div>
    </div>
  );
};

// Required Props for password field (input type)
// 1. label
// 2. error
// 3. name
// 4. defaultValue
// 5. placeholder
// 6. control
// 7. rules
// 8. icon 20X20

export const PasswordField = ({
  label,
  error,
  name,
  defaultvalue,
  placeholder,
  control,
  rules,
  icon,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultvalue,
  });

  return (
    <div>
      {error ? (
        <Text className="text-sm text-red-500 mb-1">{error}</Text>
      ) : (
        <Text className="text-sm text-gray-700 mb-1">{label}</Text>
      )}

      <div className={icon ? "relative" : ""}>
        <input
          onChange={onChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          value={value} // input value
          name={name} // send down the input name
          ref={ref} // send input ref, so we can focus on input when error appear
          placeholder={placeholder}
          type="password"
          className={
            error
              ? "w-full text-sm bg-gray-100 rounded-md outline-none p-4 border-red-400"
              : "w-full text-sm bg-gray-100 rounded-md outline-none p-4"
          }
        />

        {icon ? (
          <div className="text-gray-400 absolute top-4 left-3">{icon}</div>
        ) : null}
      </div>
    </div>
  );
};

// Required Props for (date picker)
// 1. label
// 2. error
// 3. name
// 4. defaultValue
// 5. placeholder
// 6. control
// 7. rules
// 8. icon 20X20

export const DateField = ({
  label,
  error,
  name,
  defaultValue,
  placeholder,
  control,
  rules,
  icon,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue,
  });

  return (
    <div>
      {error ? (
        <Text className="text-sm text-red-500 mb-1">{error}</Text>
      ) : (
        <Text className="text-sm text-gray-700 mb-1">{label}</Text>
      )}

      <div className={icon ? "relative" : ""}>
        <ReactDatePicker
          onChange={onChange} // send value to hook form
          onBlur={onBlur} // notify when input is touched/blur
          value={value} // input value
          name={name} // send down the input name
          ref={ref} // send input ref, so we can focus on input when error appear
          placeholderText={placeholder}
          selected={value}
          className={
            error
              ? "w-full text-sm bg-gray-100 rounded-md outline-none py-3 pl-10 border-red-400"
              : "w-full text-sm bg-gray-100 rounded-md outline-none py-3 pl-10"
          }
        />

        {icon ? (
          <div className="text-gray-300 absolute top-4 left-3">{icon}</div>
        ) : null}
      </div>
    </div>
  );
};

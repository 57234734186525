import { Images } from "../../utils/images";

export const NetworkError = () => {
  return (
    <div className="px-5 py-20 mx-auto text-center bg-white rounded-xl shadow-sm w-full sm:w-[480px]">
      <img
        src={Images.NetError}
        alt="Network error"
        width={200}
        height={150}
        className="mx-auto"
      />

      <p className="text-md font-medium text-indigo-500 mt-4">
        Something going wrong!!!
      </p>
    </div>
  );
};

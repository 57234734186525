import React, { useState, useEffect } from "react";
import { Services } from "../../http";
import { Images } from "../../utils/images";
import { Card } from "../../components/card";
import { Text } from "../../components/text";
import { Link, useHistory } from "react-router-dom";
import { Toastify } from "../../components/toastify";
import { networkErrorHandeller } from "../../helpers";
import { ResetForm } from "../../components/form/reset.form";

export const Reset = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) return history.push("/dashboard");
  }, [history]);

  /* submit data */
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await Services.Auth.reset(data);
      if (response && response.status === 200) {
        history.push(`/otp-verification?phone=${data.phone}`);
        Toastify.Success(response.data.message);
      }

      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div className="grid h-screen place-items-center">
      <div className="w-full md:w-[550px] lg:w-[650px] mx-auto p-4">
        <Card>
          <div className="text-center py-10">
            <img
              src={Images.Logo}
              alt="Logo"
              className="w-[45px] h-[45px] mx-auto mb-4"
            />

            <Text className="text-[20px] font-bold">
              Reset account password
            </Text>
          </div>

          <div className="px-2 pb-10 md:px-16">
            <ResetForm
              disabled={isLoading}
              isLoading={isLoading}
              onSubmit={(data) => handleSubmit(data)}
            />

            <div className="text-center">
              <div className="inline-flex gap-2 text-sm font-medium">
                <p className="text-gray-400">Back to</p>
                <Link to="/" className="text-primary">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

import { PieChart, Tool, Truck, FileText, MapPin } from "react-feather";
import { DashboardIndex } from "../pages/dashboard";
import { OrderIndex } from "../pages/order";
import { OrderShow } from "../pages/order/show";
import { DocumentIndex } from "../pages/document";
import { DocumentShow } from "../pages/document/show";
import { BannerIndex } from "../pages/banner";
import { BannerStore } from "../pages/banner/store";
import { AdsIndex } from "../pages/ads";
import { AddStore } from "../pages/ads/store";
import { AreaIndex } from "../pages/area";
import { AreaStore } from "../pages/area/store";
import { AreaEdit } from "../pages/area/edit";

export const appRoutes = [
  {
    title: "Dashboard",
    name: "dashboard",
    path: "/dashboard/",
    exact: true,
    inDrawer: true,
    icon: <PieChart size={20} />,
    component: DashboardIndex,
  },
  {
    title: "Order Management",
    name: "order index",
    path: "/dashboard/order",
    exact: true,
    inDrawer: true,
    icon: <Truck size={20} />,
    component: OrderIndex,
  },
  {
    title: "Show Order",
    name: "order show",
    path: "/dashboard/order/:id",
    exact: true,
    inDrawer: false,
    icon: null,
    component: OrderShow,
  },
  {
    title: "Documents Management",
    name: "documents index",
    path: "/dashboard/documents",
    exact: true,
    inDrawer: true,
    icon: <FileText size={20} />,
    component: DocumentIndex,
  },
  {
    title: "Show document",
    name: "documents show",
    path: "/dashboard/documents/:id",
    exact: true,
    inDrawer: false,
    icon: null,
    component: DocumentShow,
  },
  {
    title: "Banner",
    name: "banner index",
    path: "/dashboard/banner",
    exact: true,
    inDrawer: true,
    icon: <Tool size={20} />,
    component: BannerIndex,
  },
  {
    title: "Banner store",
    name: "banner store",
    path: "/dashboard/banner/create",
    exact: true,
    inDrawer: false,
    icon: null,
    component: BannerStore,
  },
  {
    title: "Ads",
    name: "ads index",
    path: "/dashboard/ads",
    exact: true,
    inDrawer: true,
    icon: <Tool size={20} />,
    component: AdsIndex,
  },
  {
    title: "Ads store",
    name: "ads store",
    path: "/dashboard/ads/create",
    exact: true,
    inDrawer: false,
    icon: null,
    component: AddStore,
  },
  {
    title: "Area & Charges",
    name: "area index",
    path: "/dashboard/area",
    exact: true,
    inDrawer: true,
    icon: <MapPin size={20} />,
    component: AreaIndex,
  },
  {
    title: "Area & Charge store",
    name: "area store",
    path: "/dashboard/area/create",
    exact: true,
    inDrawer: false,
    icon: null,
    component: AreaStore,
  },
  {
    title: "Area & Charge edit",
    name: "area edit",
    path: "/dashboard/area/:id",
    exact: true,
    inDrawer: false,
    icon: null,
    component: AreaEdit,
  },
];

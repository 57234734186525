import { Auth } from "./auth.http";
import { Dashboard } from "./dashboard.http";
import { Order } from "./order.http";
import { Document } from "./document.http";
import { Banner } from "./banner.http";
import { Ads } from "./ads.http";
import { Area } from "./area.http";

export const Services = {
  Auth,
  Dashboard,
  Order,
  Document,
  Banner,
  Ads,
  Area,
};

import { useForm } from "react-hook-form";
import { PrimaryButtonV2 } from "../button";
import { isValidPhone } from "../../helpers";
import { TextField, PasswordField } from "../input-field";

export const LoginForm = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Phone */}
      <div className="mb-6">
        <TextField
          label="Phone number"
          name="phone"
          control={control}
          defaultvalue={""}
          error={errors.phone && errors.phone.message}
          placeholder={"01XX-XXXX-XXX"}
          rules={{
            required: "Phone number is required",
            pattern: {
              value: isValidPhone(),
              message: "Invalid phone number.",
            },
          }}
        />
      </div>

      {/* Password */}
      <div className="mb-6">
        <PasswordField
          label="Password"
          name="password"
          control={control}
          defaultvalue={""}
          error={errors.password && errors.password.message}
          placeholder={"Enter password"}
          rules={{ required: "Password is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="flex flex-col">
        <div className="mb-6">
          <PrimaryButtonV2 type="submit" disabled={props.isLoading}>
            {props.isLoading ? "Signing in..." : "Sign in"}
          </PrimaryButtonV2>
        </div>
      </div>
    </form>
  );
};

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Modal } from "../../components/modal";
import { DataTable } from "../../components/table";
import { NetworkError } from "../../components/501";
import { PrimaryButton } from "../../components/button";
import { OrderStatus } from "../../components/order-status";
import { OrderInfoPreloader } from "../../components/loader";
import { Toastify } from "../../components/toastify";
import { Services } from "../../http";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "../../components/print/ComponentToPrint";

const smTextStyle = "text-sm font-normal mb-[7px] text-gray-700";

export const OrderShow = () => {
  const componentRef = useRef();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [show, setShow] = useState(false);
  const [updating, setUpdating] = useState(false);

  /* order cancel */
  const orderCancel = async (id) => {
    try {
      const response = await Services.Order.orderCancel(id);
      if (response && response.status === 200) {
        Toastify.Success("This order is canceled.");
        history.push("/dashboard/order");
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* fetch data */
  const fetchData = useCallback(async (id) => {
    try {
      setLoading(true);
      setServerError(false);
      setData(null);
      const response = await Services.Order.show(id);
      if (response && response.status === 200) {
        setData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id, fetchData]);

  /* data columns */
  const columns = [
    {
      name: "",
      width: "100px",
      cell: (row) => (
        <img src={row.image_url} alt="..." width={50} height={50} />
      ),
    },
    {
      name: "Product",
      grow: 1,
      cell: (row) => (
        <div>
          <p className="text-sm font-medium mb-[2px]">{row.name}</p>
          <p className="text-[12px] font-normal text-gray-500">
            SKU: {row.sku}
          </p>
        </div>
      ),
    },
    {
      name: "Quantity",
      width: "90px",
      right: true,
      selector: (row) => row.quantity,
    },
    {
      name: "Price",
      width: "130px",
      right: true,
      selector: (row) => row.price,
    },
    {
      name: "Sub total",
      width: "150px",
      right: true,
      cell: (row) => (
        <div>{parseInt(row.quantity) * parseInt(row.price)} tk</div>
      ),
    },
  ];

  const handleShow = () => setShow(!show);

  /* Handle update order status */
  const handleUpdate = async (data) => {
    try {
      setUpdating(true);
      const response = await Services.Order.update(id, data);
      console.log("status", response);
      if (response.status === 200) {
        fetchData(id);
        Toastify.Success(response.data.message);
      }
      setUpdating(false);
      setShow(false);
    } catch (error) {
      if (error) {
        setUpdating(false);
        Toastify.Error("Something going wrong, Try again!");
      }
    }
  };

  return (
    <div className="grow pb-4">
      {isLoading && !serverError && !data ? <OrderInfoPreloader /> : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}

      {!isLoading && !serverError && data ? (
        <div>
          {/* Order info card */}
          <div className="w-full rounded-lg overflow-hidden shadow bg-white mb-4">
            <div className="p-4">
              <Text className="text-md font-medium">Order info.</Text>
            </div>
            <div className="pb-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="px-4 flex">
                  <div className="w-[130px]">
                    <Text className={smTextStyle}>Name</Text>
                    <Text className={smTextStyle}>Phone</Text>
                    <Text className={smTextStyle}>Order status</Text>
                    <Text className={smTextStyle}>Delivery address</Text>
                    <ReactToPrint
                      trigger={() => (
                        <button className="px-5 py-[10px] rounded-lg transition-all text-purple-500 bg-purple-100 hover:bg-purple-200 text-[15px] font-medium disabled:bg-purple-100 disabled:text-white ">
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                  <div className="flex-grow">
                    <Text className={smTextStyle}>: {data?.order?.name}</Text>
                    <Text className={smTextStyle}>: {data?.order?.phone}</Text>
                    <Text className={smTextStyle}>
                      : {data?.order?.order_status}
                    </Text>
                    <Text className={smTextStyle}>
                      : {data?.order?.delivery_address}
                    </Text>
                  </div>
                </div>
                <div className="px-4 flex">
                  <div className="min-w-[130px]">
                    <Text className={smTextStyle}>Sub total</Text>
                    <Text className={smTextStyle}>Delivery cost</Text>
                    <Text className={smTextStyle}>Grand total</Text>
                    <Text className={smTextStyle}>Anything need</Text>
                    {data?.order ? (
                      <div className="pt-3">
                        <PrimaryButton onClick={handleShow}>
                          Change status
                        </PrimaryButton>
                      </div>
                    ) : null}

                    {/* order change status */}
                    {/* order status */}
                    <div className=" float-left">
                      {/* cancel */}
                      {data?.order?.order_status === "Canceled" ? (
                        <p className=" bg-red-500 hover:bg-red-500 text-white border px-3 p-1 text-[12px] rounded-full w-52 text-center">
                          order canceled by you
                        </p>
                      ) : (
                        ""
                      )}

                      {/* created */}
                      {data?.order?.order_status === "Created" ? (
                        <button
                          className="px-5 py-[2px] transition-all bg-red-400 hover:bg-red-500 text-white rounded-full"
                          onClick={() => orderCancel(id)}
                        >
                          Order cancel
                        </button>
                      ) : (
                        ""
                      )}

                      {/* Accepted */}
                      {data?.order?.order_status === "Accepted" ? (
                        <button
                          className="px-5 py-[2px] transition-all bg-red-400 hover:bg-red-500 text-white rounded-full"
                          onClick={() => orderCancel(id)}
                        >
                          Order cancel
                        </button>
                      ) : (
                        ""
                      )}

                      {/* Pending */}
                      {data?.order?.order_status === "Pending" ? (
                        <button
                          className="px-5 py-[2px] transition-all bg-red-400 hover:bg-red-500 text-white rounded-full"
                          onClick={() => orderCancel(id)}
                        >
                          Order cancel
                        </button>
                      ) : (
                        ""
                      )}

                      {/* Processing */}
                      {data?.order?.order_status === "Processing" ? (
                        <button
                          className="px-5 py-[2px] transition-all bg-red-400 hover:bg-red-500 text-white rounded-full"
                          onClick={() => orderCancel(id)}
                        >
                          Order cancel
                        </button>
                      ) : (
                        ""
                      )}

                      {/* shipped */}
                      {data?.order?.order_status === "Shipped" ? (
                        <p className="border-primary bg-primary text-white border px-3 p-1 text-[12px] rounded-full w-52 text-center">
                          order already shipped
                        </p>
                      ) : (
                        ""
                      )}

                      {/* Delivered */}
                      {data?.order?.order_status === "Delivered" ? (
                        <p className="border-primary bg-primary text-white border px-3 p-1 text-[12px] rounded-full w-52 text-center">
                          {" "}
                          order already delivered
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex-grow">
                    <Text className={smTextStyle}>
                      : {data?.order?.sub_total} tk
                    </Text>
                    <Text className={smTextStyle}>
                      : {data?.order?.delivery_cost} tk
                    </Text>
                    <Text className={smTextStyle}>
                      : {data?.order?.grand_total} tk
                    </Text>
                    <Text className={smTextStyle}>
                      : {data?.order?.any_query}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Order products card */}
          <div className="w-full rounded-lg overflow-hidden shadow bg-white">
            <div className="px-4 pt-4">
              <p className="text-md font-medium">Products</p>
            </div>
            <div>
              <DataTable
                data={data.products}
                columns={columns}
                loading={isLoading}
                noDataMessage="No products available."
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* Status modal */}
      <Modal show={show} onHide={handleShow} title="Change order status.">
        <OrderStatus
          onLoading={updating}
          onSubmit={(data) => handleUpdate(data)}
        />
      </Modal>

      <div className="w-full rounded-lg overflow-hidden shadow bg-white mb-4 mt-4">
        <ComponentToPrint id={data?.order?.id} ref={componentRef} />
      </div>
    </div>
  );
};

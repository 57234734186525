export const TableLoader = () => {
  return (
    <div className="p-4 w-full mx-auto">
      <div className="animate-pulse">
        {[...Array(6).keys()].map((i) => (
          <div key={i} className="grid grid-cols-3 gap-4 mb-4">
            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const OrderInfoPreloader = () => {
  return (
    <div className="w-full rounded-lg overflow-hidden shadow p-4 bg-white">
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 animate-pulse">
        {[...Array(2).keys()].map((i) => (
          <div key={i}>
            {[...Array(4).keys()].map((j) => (
              <div
                key={j}
                className="h-2 w-full bg-slate-200 rounded-full mb-3"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

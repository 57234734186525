import React from "react";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "../button";
import { TextField } from "../input-field";

export const CostForm = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Delivery charge */}
      <div className="mb-4">
        <TextField
          label="Delivery charge"
          name="delivery_charge"
          type={"number"}
          control={control}
          defaultvalue={""}
          error={errors.delivery_charge && errors.delivery_charge.message}
          placeholder={"Enter delivery charge."}
          rules={{ required: "Delivery charge is required." }}
        />
      </div>

      {/* Delivery charge */}
      <div className="mb-4">
        <TextField
          label="Subtotal amount"
          name="sub_total"
          type={"number"}
          control={control}
          defaultvalue={""}
          error={errors.sub_total && errors.sub_total.message}
          placeholder={"Enter subtotal amount."}
          rules={{ required: "Subtotal amount is required." }}
        />
      </div>

      {/* Submit button */}
      <div className="text-right">
        <PrimaryButton type="submit" disabled={props.isLoading}>
          {props.isLoading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};

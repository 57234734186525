import React, { useCallback, useEffect, useState } from "react";
import { Services } from "../../http";
import { Link } from "react-router-dom";
import { Plus, Trash2 } from "react-feather";
import { Card } from "../../components/card";
import { Modal } from "../../components/modal";
import { DataTable } from "../../components/table";
import { networkErrorHandeller } from "../../helpers";
import {
  CircleButton,
  DangerButton,
  PrimaryButton,
} from "../../components/button";
import { Toastify } from "../../components/toastify";

export const BannerIndex = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [willDestroy, setWillDestroy] = useState({
    show: false,
    id: null,
    loading: false,
  });

  /* fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Services.Banner.index();
      if (response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* handle destroy */
  const handleDestroy = async () => {
    try {
      setWillDestroy({ ...willDestroy, loading: true });
      const response = await Services.Banner.destroy(willDestroy.id);
      if (response && response.status === 200) {
        fetchData();
        Toastify.Success(response.data.message);
      }
      setWillDestroy({ id: null, loading: false, show: false });
    } catch (error) {
      if (error) {
        setWillDestroy({ id: null, loading: false, show: false });
        networkErrorHandeller(error);
      }
    }
  };

  // data columns
  const columns = [
    {
      name: "XL",
      maxWidth: "100px",
      cell: (row) => <img src={row.image_xl} alt="...." />,
    },
    {
      name: "LG",
      maxWidth: "100px",
      cell: (row) => <img src={row.image_lg} alt="...." />,
    },
    {
      name: "SM",
      maxWidth: "100px",
      cell: (row) => <img src={row.image_sm} alt="...." />,
    },
    {
      name: "XS",
      maxWidth: "100px",
      cell: (row) => <img src={row.image_xs} alt="...." />,
    },
    {
      name: "Redirect To",
      grow: 1,
      selector: (row) => <p>{row.page_link}</p>,
    },
    {
      name: "Action",
      maxWidth: "60px",
      cell: (row) => (
        <DangerButton
          type="button"
          className="rounded-full !p-3"
          onClick={() =>
            setWillDestroy({ id: row.id, loading: false, show: true })
          }
        >
          <Trash2 size={18} />
        </DangerButton>
      ),
    },
  ];

  return (
    <div>
      <Card className="mb-3 flex justify-between">
        <div>
          <p className="text-[15px] pt-[6px] text-gray-800 font-medium">
            Active banners
          </p>
        </div>
        <div>
          <Link to="/dashboard/banner/create">
            <CircleButton type="button">
              <Plus size={18} />
            </CircleButton>
          </Link>
        </div>
      </Card>

      <Card>
        <DataTable data={data} columns={columns} loading={isLoading} />
      </Card>

      {/* Delete confirmation modal */}
      <Modal
        show={willDestroy.show}
        onHide={() => setWillDestroy({ id: null, loading: false, show: false })}
        title="Are you sure?"
      >
        <p className="mb-4">Want to delete this banner.</p>
        <div>
          <DangerButton
            type="button"
            className="mr-2"
            onClick={() => handleDestroy()}
            disabled={willDestroy.loading}
          >
            {willDestroy.loading ? "Deleting..." : "Yes"}
          </DangerButton>
          <PrimaryButton
            type="button"
            onClick={() =>
              setWillDestroy({ id: null, loading: false, show: false })
            }
            disabled={willDestroy.loading}
          >
            No
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { Services } from "../../http";
import { ChevronLeft } from "react-feather";
import { Card } from "../../components/card";
import { Link, useHistory } from "react-router-dom";
import { Toastify } from "../../components/toastify";
import { networkErrorHandeller } from "../../helpers";
import { CircleButton } from "../../components/button";
import { AreaForm } from "../../components/form/area.form";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TableLoader } from "../../components/loader";
import { NetworkError } from "../../components/501";

export const AreaEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  /** Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Services.Area.show(id);
      if (response && response.status === 200) {
        setData(response.data?.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setServerError(true);
      networkErrorHandeller(error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* handle form submit to server */
  const handleSubmit = async (data) => {
    try {
      setUpdateLoading(true);
      const response = await Services.Area.update(id, data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }

      setUpdateLoading(false);
      history.push("/dashboard/area");
    } catch (error) {
      if (error) {
        setUpdateLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <Card className="mb-3 flex justify-between">
        <div>
          <p className="text-[15px] pt-[6px] text-gray-800 font-medium">
            Update area & charges
          </p>
        </div>
        <div>
          <Link to="/dashboard/area">
            <CircleButton type="button">
              <ChevronLeft size={18} />
            </CircleButton>
          </Link>
        </div>
      </Card>

      {isLoading && !serverError && !data ? <TableLoader /> : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}

      {!isLoading && !serverError && data ? (
        <Card className="!p-4 md:!p-8 w-full md:w-[600px] mx-auto">
          <AreaForm
            data={data}
            loading={updateLoading}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      ) : null}
    </div>
  );
};

import { privateRequest } from "../config/axios.config";

/* List of resources */
const index = async () => {
  return privateRequest.get(`/area`);
};

/* Store new resources */
const store = async (data) => {
  return privateRequest.post(`/area`, data);
};

/* Shoq specific resources */
const show = async (id) => {
  return privateRequest.get(`/area/${id}`);
};

/* Update specific resource */
const update = async (id, data) => {
  return privateRequest.put(`/area/${id}`, data);
};

/* Destroy specific resource */
const destroy = async (id) => {
  return privateRequest.delete(`/area/${id}`);
};

export const Area = {
  index,
  store,
  show,
  update,
  destroy,
};

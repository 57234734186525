import { useForm } from "react-hook-form";
import { TextField } from "../input-field";
import { PrimaryButtonV2 } from "../button";

export const AreaForm = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Address input */}
      <div className="mb-6">
        <TextField
          label="Address"
          name="address"
          control={control}
          defaultvalue={props?.data?.address}
          error={errors.address && errors.address.message}
          placeholder={"Enter address"}
          rules={{ required: "Address is required" }}
        />
      </div>

      {/* Delivery charge input */}
      <div className="mb-6">
        <TextField
          label="Delivery charge"
          name="delivery_charge"
          type="number"
          control={control}
          defaultvalue={props?.data?.delivery_charge}
          error={errors.delivery_charge && errors.delivery_charge.message}
          placeholder={"Enter charge"}
          rules={{ required: "Charge is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="flex flex-col">
        <div className="mb-6">
          <PrimaryButtonV2 type="submit" disabled={props.loading}>
            {props.loading ? "Please wait..." : "Submit"}
          </PrimaryButtonV2>
        </div>
      </div>
    </form>
  );
};

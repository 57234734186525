import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Modal } from "../../components/modal";
import { NetworkError } from "../../components/501";
import { NoContent } from "../../components/404";
import { PrimaryButton } from "../../components/button";
import { OrderStatus } from "../../components/order-status";
import { OrderInfoPreloader } from "../../components/loader";
import { CostForm } from "../../components/form/cost.form";
import { Toastify } from "../../components/toastify";
import { Services } from "../../http";
import { networkErrorHandeller } from "../../helpers";

const smTextStyle = "text-sm font-normal mb-[7px] text-gray-700";

export const DocumentShow = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [show, setShow] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showCostModal, setShowCostModal] = useState(false);
  const [updatingCost, setUpdatingCost] = useState(false);

  /* fetch data */
  const fetchData = useCallback(async (id) => {
    try {
      const response = await Services.Document.show(id);
      if (response && response.status === 200) {
        setData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id, fetchData]);

  /* Handle order status modal */
  const handleShow = () => setShow(!show);

  /* Handle cost modal */
  const handleCostModal = () => setShowCostModal(!showCostModal);

  /* Handle update order status */
  const handleUpdate = async (data) => {
    try {
      const formData = {
        status: data.order_status,
      };
      setUpdating(true);

      const response = await Services.Document.changeStatus(id, formData);
      if (response.status === 200) {
        Toastify.Success("Status updated.");
        fetchData(id);
      }
      setUpdating(false);
      setShow(false);
    } catch (error) {
      if (error) {
        setUpdating(false);
        networkErrorHandeller(error);
      }
    }
  };

  /* Handle add costs */
  const handleAddCost = async (data) => {
    try {
      setUpdatingCost(true);
      const response = await Services.Document.addCharge(id, data);
      if (response.status === 200) {
        Toastify.Success("Cost added.");
        fetchData(id);
      }

      setUpdatingCost(false);
      setShowCostModal(false);
    } catch (error) {
      if (error) {
        setUpdatingCost(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div className="grow pb-4">
      {isLoading && !serverError && !data ? <OrderInfoPreloader /> : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !serverError && !data ? (
        <NoContent message="Document not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <div>
          {/* Order & Cost info card */}
          <div className="w-full rounded-lg overflow-hidden shadow bg-white">
            <div className="p-4">
              <Text className="text-md font-medium">Customer & Cost info.</Text>
            </div>
            <div className="pb-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="px-4 flex">
                  <div className="w-[130px]">
                    <Text className={smTextStyle}>Name</Text>
                    <Text className={smTextStyle}>Phone</Text>
                    <Text className={smTextStyle}>Status</Text>
                    <Text className={smTextStyle}>Delivery date</Text>
                    <Text className={smTextStyle}>Delivery address</Text>
                  </div>
                  <div className="flex-grow">
                    <Text className={smTextStyle}>: {data?.customer_name}</Text>
                    <Text className={smTextStyle}>
                      : {data?.customer_phone}
                    </Text>
                    <Text className={smTextStyle}>: {data?.status}</Text>
                    <Text className={smTextStyle}>
                      : {data?.delivered_date}
                    </Text>
                    <Text className={smTextStyle}>
                      : {data?.delivery_address}
                    </Text>
                  </div>
                </div>
                <div className="px-4 flex">
                  <div className="min-w-[130px]">
                    <Text className={smTextStyle}>Sub total</Text>
                    <Text className={smTextStyle}>Delivery cost</Text>
                    <Text className={smTextStyle}>Grand total</Text>
                  </div>
                  <div className="flex-grow">
                    <Text className={smTextStyle}>
                      :{" "}
                      {data.sub_total
                        ? parseFloat(data.sub_total).toFixed(2) + " tk"
                        : "N/A"}
                    </Text>
                    <Text className={smTextStyle}>
                      :{" "}
                      {data.delivery_charge
                        ? parseFloat(data.delivery_charge).toFixed(2) + " tk"
                        : "N/A"}
                    </Text>
                    <Text className={smTextStyle}>
                      :{" "}
                      {data.grand_total
                        ? parseFloat(data.grand_total).toFixed(2) + " tk"
                        : "N/A"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Action button container */}
          <div className="w-full rounded-lg overflow-hidden shadow p-4 flex gap-2 my-4 bg-white">
            <PrimaryButton onClick={handleShow}>Change status</PrimaryButton>
            <PrimaryButton onClick={handleCostModal}>Add Costs</PrimaryButton>
            <a href={data.file} target="_blank" rel="noreferrer">
              <PrimaryButton>Open File</PrimaryButton>
            </a>
          </div>

          {/* Document information */}
          <div className="w-full rounded-lg overflow-hidden shadow bg-white">
            <div className="p-4">
              <Text className="text-md font-medium">Document info.</Text>
            </div>
            <div className="pb-4">
              <div className="px-4 flex">
                <div className="w-[130px]">
                  <Text className={smTextStyle}>Copies</Text>
                  <Text className={smTextStyle}>Print color</Text>
                  <Text className={smTextStyle}>Paper size</Text>
                  <Text className={smTextStyle}>Print side</Text>
                  <Text className={smTextStyle}>Binding type</Text>
                </div>
                <div className="flex-grow">
                  <Text className={smTextStyle}>: {data?.copies}</Text>
                  <Text className={smTextStyle}>: {data?.print_color}</Text>
                  <Text className={smTextStyle}>: {data?.paper_size}</Text>
                  <Text className={smTextStyle}>: {data?.print_side}</Text>
                  <Text className={smTextStyle}>: {data?.binding_type}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Status modal */}
      <Modal show={show} onHide={handleShow} title="Change order status.">
        <OrderStatus
          onLoading={updating}
          onSubmit={(data) => handleUpdate(data)}
        />
      </Modal>

      {/* Cost add modal */}
      <Modal
        show={showCostModal}
        onHide={handleCostModal}
        title="Add delivery & sub total cost."
      >
        <CostForm
          isLoading={updatingCost}
          onSubmit={(data) => handleAddCost(data)}
        />
      </Modal>
    </div>
  );
};

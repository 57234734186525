import { useState } from "react";
import { PrimaryButtonV2 } from "../button";
import OtpInput from "react18-input-otp";

export const OtpVerificationForm = (props) => {
  const [otpValue, setOtpValue] = useState("");
  const [isError, setError] = useState(false);

  /* handle input change */
  const handleInputChange = (enteredOtp) => {
    setOtpValue(enteredOtp);
    setError(false);
  };

  /* handle form submit */
  const onSubmit = (event) => {
    event.preventDefault();

    if (otpValue.length < 4) {
      return setError(true);
    }

    props.onSubmit(otpValue);
  };

  return (
    <form onSubmit={onSubmit}>
      {/* OTP input */}
      <div className="mb-6">
        <OtpInput
          value={otpValue}
          onChange={handleInputChange}
          numInputs={4}
          isInputNum
          isDisabled={props.loading || props.resendLoading}
          hasErrored={isError}
          containerStyle="!justify-center !mb-7"
          separator={<span className="w-[15px] sm:w-[20px]" />}
          inputStyle="border !h-[55px] !w-[55px] rounded-lg !mx-auto font-medium text-lg focus:outline-none focus-visible:none"
          errorStyle="border border-red-300"
        />
      </div>

      {/* Submit button */}
      <div className="flex flex-col">
        <div className="text-center mb-6">
          <PrimaryButtonV2 type="submit" disabled={props.isLoading}>
            {props.isLoading ? "Loading..." : "Verify"}
          </PrimaryButtonV2>
        </div>
      </div>

      {/* Resend container */}
      <div className="flex justify-center text-center gap-3">
        <p className="text-muted text-sm">{`Didn’t get code?`}</p>
        <button
          type="button"
          className="text-sm font-medium text-primary disabled:text-gray-400"
          disabled={props.loading || props.resendLoading}
          onClick={props.resendOtp}
        >
          {props.resendLoading ? "Sending..." : "Resend"}
        </button>
      </div>
    </form>
  );
};

import { useForm } from "react-hook-form";
import { PrimaryButtonV2 } from "../button";
import { PasswordField } from "../input-field";

export const SetPasswordForm = (props) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.password !== data.c_password) {
      return setError("c_password", {
        type: "custom",
        message: "The passwords do not match.",
      });
    }
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Password */}
      <div className="mb-6">
        <PasswordField
          label="Password"
          name="password"
          control={control}
          defaultvalue={""}
          error={errors.password && errors.password.message}
          placeholder={"Enter password"}
          rules={{ required: "Password is required" }}
        />
      </div>

      {/* Confirm password */}
      <div className="mb-6">
        <PasswordField
          label="Confirm password"
          name="c_password"
          control={control}
          defaultvalue={""}
          error={errors.c_password && errors.c_password.message}
          placeholder={"Re-type password"}
          rules={{ required: "Confirm password is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="flex flex-col">
        <div className="mb-6">
          <PrimaryButtonV2 type="submit" disabled={props.isLoading}>
            {props.isLoading ? "Saving..." : "Save Changes"}
          </PrimaryButtonV2>
        </div>
      </div>
    </form>
  );
};

import { Toastify } from "../components/toastify";

// Phone number valid check
export const isValidPhone = () => {
  const regex = /^(?:\+88|88)?(01[3-9]\d{8})$/i;
  return regex;
};

// E-mail valid check
export const isValidEmail = () => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex;
};

// Date to string
export const dateToString = (date) => {
  date = new Date(date);
  return date.toDateString();
};

// Date to date
export const dateTodate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/* Remove token */
export const removeToken = () => {
  localStorage.removeItem("token");
  return true;
};

/* Generate array from integer number */
export const arrayFromNumber = (data) => {
  const array = [];
  for (let i = 0; i < data; i++) array.push(i);
  return array;
};

/* Network error handeller */
export const networkErrorHandeller = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    const obj = error.response.data.errors;
    Object.keys(obj).map((key) => {
      if (obj[key].length > 0) {
        for (let i = 0; i < obj[key].length; i++) {
          if (obj[key][i] === "Invalid token.") {
            removeToken();
            window.location.replace("/");
          } else if (obj[key][i] === "Token is expired.") {
            removeToken();
            window.location.replace("/");
          } else if (obj[key][i] === "Authorization Token not found.") {
            removeToken();
            window.location.replace("/");
          } else {
            Toastify.Error(obj[key][i]);
          }
        }
      }
    });
    return;
  } else {
    return Toastify.Error("Something going wrong, Try again.");
  }
};

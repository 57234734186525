import React, { useState, useEffect } from "react";
import { Services } from "../../http";
import { Images } from "../../utils/images";
import { Card } from "../../components/card";
import { Text } from "../../components/text";
import { Link, useHistory } from "react-router-dom";
import { Toastify } from "../../components/toastify";
import { networkErrorHandeller } from "../../helpers";
import { LoginForm } from "../../components/form/login.form";

export const Login = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) return history.push("/dashboard");
  }, [history]);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await Services.Auth.login(data);
      if (response && response.status === 200) {
        if (response?.data?.data?.user?.role !== "admin") {
          setLoading(false);
          return Toastify.Error("Invalid login credentials.");
        }
        const token = response.data.data.access_token;
        localStorage.setItem("token", token);
        history.push("/dashboard");
      }

      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div className="grid h-screen place-items-center">
      <div className="w-full md:w-[550px] lg:w-[650px] mx-auto p-4">
        <Card>
          <div className="text-center py-10">
            <img
              src={Images.Logo}
              alt="Logo"
              className="w-[45px] h-[45px] mx-auto mb-4"
            />

            <Text className="text-[20px] font-bold">Sign to admin account</Text>
          </div>

          <div className="px-2 pb-10 md:px-16">
            <LoginForm
              disabled={isLoading}
              isLoading={isLoading}
              onSubmit={(data) => handleSubmit(data)}
            />

            <div className="text-center">
              <div className="inline-flex gap-2 text-sm font-medium">
                <p className="text-gray-400">Did you forget your password?</p>
                <Link to="/reset-password" className="text-primary">
                  Reset Password
                </Link>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

import { Text } from "../text";

export const DashboardCard = ({ icon, icon_bg_color, title, number }) => {
  return (
    <div className="rounded-xl shadow-sm p-5 bg-white">
      <div
        className={`grid place-items-center w-10 h-10 rounded-full mb-4 ${icon_bg_color}`}
      >
        {icon}
      </div>
      <div className="flex justify-between">
        <Text className="text-sm mt-2 text-gray-400">{title}</Text>
        <Text className="text-2xl font-bold">{number}</Text>
      </div>
    </div>
  );
};

/** Preloader */
export const DashboardCardPreloader = () => {
  return (
    <div className="rounded-xl shadow-sm p-5 bg-white">
      <div className="w-10 h-10 rounded-full mb-7 animate-pulse bg-slate-200" />
      <div className="h-3 w-20 rounded-full animate-pulse bg-slate-200" />
    </div>
  );
};

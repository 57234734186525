import React, { useCallback, useEffect } from "react";
import {
  DashboardCard,
  DashboardCardPreloader,
} from "../../components/card/dashboard.card";
import { DollarSign, ShoppingCart, UserCheck, Users } from "react-feather";
import { useState } from "react";
import { Services } from "../../http";
import { arrayFromNumber, networkErrorHandeller } from "../../helpers";
import { NetworkError } from "../../components/501";

export const DashboardIndex = () => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);

  /* fetch data */
  const fetchData = useCallback(async () => {
    try {
      const response = await Services.Dashboard.index();
      if (response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {/* Data loading handle */}
      {isLoading && !serverError && !data ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-5">
          {arrayFromNumber(6).map((item) => (
            <DashboardCardPreloader key={item} />
          ))}
        </div>
      ) : null}

      {/* Data error handle */}
      {!isLoading && !data && serverError ? <NetworkError /> : null}

      {/* Data preview handle */}
      {!isLoading && !serverError && data ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-5">
          {/* sales history start */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 md:gap-5">
            <DashboardCard
              icon={<Users size={20} className="text-primary" />}
              icon_bg_color={"bg-orange-50"}
              title="Total users"
              number={data.users}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-indigo-500" />}
              icon_bg_color={"bg-indigo-50"}
              title="Total sales"
              number={parseFloat(data.sales.total)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-sky-500" />}
              icon_bg_color={"bg-sky-50"}
              title="Accepted sales"
              number={parseFloat(data.sales.accepted)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-primary" />}
              icon_bg_color={"bg-orange-50"}
              title="Pending sales"
              number={parseFloat(data.sales.pending)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-violet-500" />}
              icon_bg_color={"bg-violet-50"}
              title="Processing sales"
              number={parseFloat(data.sales.processing)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-cyan-500" />}
              icon_bg_color={"bg-cyan-50"}
              title="Shipped sales"
              number={parseFloat(data.sales.shipped)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-green-500" />}
              icon_bg_color={"bg-green-50"}
              title="Delivered sales"
              number={parseFloat(data.sales.delivered)}
            />
            <DashboardCard
              icon={<DollarSign size={20} className="text-red-500" />}
              icon_bg_color={"bg-red-50"}
              title="Canceled sales"
              number={parseFloat(data.sales.canceled)}
            />
          </div>
          {/* sales history end */}
          

          {/* orders history start */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 md:gap-5">
            <DashboardCard
              icon={<UserCheck size={20} className="text-primary" />}
              icon_bg_color={"bg-orange-50"}
              title="Total admin"
              number={data.admins}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-indigo-500" />}
              icon_bg_color={"bg-indigo-50"}
              title="Created orders"
              number={data.orders.created}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-sky-500" />}
              icon_bg_color={"bg-sky-50"}
              title="Accepted orders"
              number={data.orders.accepted}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-primary" />}
              icon_bg_color={"bg-orange-50"}
              title="Pending orders"
              number={data.orders.pending}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-violet-500" />}
              icon_bg_color={"bg-violet-50"}
              title="Processing orders"
              number={data.orders.processing}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-cyan-500" />}
              icon_bg_color={"bg-cyan-50"}
              title="Shipped orders"
              number={data.orders.shipped}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-green-500" />}
              icon_bg_color={"bg-green-50"}
              title="Delivered orders"
              number={data.orders.delivered}
            />
            <DashboardCard
              icon={<ShoppingCart size={20} className="text-red-500" />}
              icon_bg_color={"bg-red-50"}
              title="Canceled orders"
              number={data.orders.canceled}
            />
          </div>
          {/* orders history end */}
        </div>
      ) : null}
    </div>
  );
};

import React from "react";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "../button";
import { FileInput } from "../input-field/file.input";

export const AdsForm = (props) => {
  const {
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  /* handle set value to form */
  const handleSetValue = ({ key, value }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  /* handle submission */
  const onSubmit = (data) => {
    if (!data.image_xl) {
      return setError("image_xl", {
        type: "custom",
        message: "XL ads photo is required.",
      });
    }

    if (!data.image_lg) {
      return setError("image_lg", {
        type: "custom",
        message: "LG ads photo is required.",
      });
    }

    if (!data.image_sm) {
      return setError("image_sm", {
        type: "custom",
        message: "SM ads photo is required.",
      });
    }

    if (!data.image_xs) {
      return setError("image_xs", {
        type: "custom",
        message: "XS ads photo is required.",
      });
    }

    const formData = new FormData();
    formData.append("image_xl", data.image_xl);
    formData.append("image_lg", data.image_lg);
    formData.append("image_sm", data.image_sm);
    formData.append("image_xs", data.image_xs);

    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* XL ads */}
      <div className="mb-6">
        {errors.image_xl && errors.image_xl.message ? (
          <p className="text-red-500 mb-2 text-sm">{errors.image_xl.message}</p>
        ) : (
          <p className="text-gray-500 mb-2 text-sm">XL ads photo</p>
        )}
        <FileInput
          onSelected={(data) =>
            handleSetValue({ key: "image_xl", value: data })
          }
        />
      </div>

      {/* LG ads */}
      <div className="mb-6">
        {errors.image_lg && errors.image_lg.message ? (
          <p className="text-red-500 mb-2 text-sm">{errors.image_lg.message}</p>
        ) : (
          <p className="text-gray-500 mb-2 text-sm">LG ads photo</p>
        )}
        <FileInput
          onSelected={(data) =>
            handleSetValue({ key: "image_lg", value: data })
          }
        />
      </div>

      {/* SM ads */}
      <div className="mb-6">
        {errors.image_sm && errors.image_sm.message ? (
          <p className="text-red-500 mb-2 text-sm">{errors.image_sm.message}</p>
        ) : (
          <p className="text-gray-500 mb-2 text-sm">SM ads photo</p>
        )}
        <FileInput
          onSelected={(data) =>
            handleSetValue({ key: "image_sm", value: data })
          }
        />
      </div>

      {/* XS ads */}
      <div className="mb-6">
        {errors.image_xs && errors.image_xs.message ? (
          <p className="text-red-500 mb-2 text-sm">{errors.image_xs.message}</p>
        ) : (
          <p className="text-gray-500 mb-2 text-sm">XS ads photo</p>
        )}
        <FileInput
          onSelected={(data) =>
            handleSetValue({ key: "image_xs", value: data })
          }
        />
      </div>

      {/* Submit button */}
      <div className="text-right">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Uploading..." : "Upload"}
        </PrimaryButton>
      </div>
    </form>
  );
};

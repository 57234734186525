import React, { useState, useEffect } from "react";
import { Services } from "../../http";
import { Images } from "../../utils/images";
import { Card } from "../../components/card";
import { Text } from "../../components/text";
import { useHistory } from "react-router-dom";
import { Toastify } from "../../components/toastify";
import { networkErrorHandeller } from "../../helpers";
import { useQuery } from "../../hooks/usequery.hook";
import { SetPasswordForm } from "../../components/form/set-password.form";

export const SetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const phone = query.get("phone");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) return history.push("/dashboard");
    if (!phone) return history.push("/reset-password");
  }, [phone, history]);

  /* handle form submission */
  const handleSubmit = async (data) => {
    try {
      const formData = {
        phone,
        ...data,
      };
      setLoading(true);
      const response = await Services.Auth.setPassword(formData);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
        history.push("/");
      }

      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
        if (error?.response?.data?.errors?.password_reset_otp_expired) {
          history.push("/reset-password");
        }
      }
    }
  };

  return (
    <div className="grid h-screen place-items-center">
      <div className="w-full md:w-[550px] lg:w-[650px] mx-auto p-4">
        <Card>
          <div className="text-center py-10">
            <img
              src={Images.Logo}
              alt="Logo"
              className="w-[45px] h-[45px] mx-auto mb-4"
            />

            <Text className="text-[20px] font-bold">Set password</Text>
            <div className="px-6">
              <p className="text-center text-sm font-medium text-gray-400">
                Change your new password.
              </p>
            </div>
          </div>

          <div className="px-2 pb-10 md:px-16">
            <SetPasswordForm
              disabled={isLoading}
              isLoading={isLoading}
              onSubmit={(data) => handleSubmit(data)}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

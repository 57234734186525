import { useState } from "react";
import { Images } from "../../utils/images";

export const FileInput = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  /* handle change */
  const handleChange = (event) => {
    const file = event.target.files[0];
    const objURL = URL.createObjectURL(file);
    setSelectedFile(objURL);
    props.onSelected(file);
  };

  return (
    <div className="flex items-center space-x-6">
      <div className="shrink-0">
        <img
          className="h-16 w-32 object-cover"
          src={selectedFile || Images.Dummy}
          alt="Current profile"
        />
      </div>
      <label className="block">
        <span className="sr-only">Choose photo</span>
        <input
          type="file"
          className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-violet-50 file:text-violet-700
      hover:file:bg-violet-100
    "
          onChange={(event) => handleChange(event)}
        />
      </label>
    </div>
  );
};
